<script>
import axios from "axios";

export default {
  data: () => {
    return {
      url: "",
      shortUrl: "",
      show: true,
      button: "Copy"
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.redirect();
    });
  },
  methods: {
    shorten: async function (url) {
      let shortUrl;
      const base_url = "hlc.im/";
      try {
        const api = process.env.VUE_APP_API;
        const response = await axios.post(api, {
          'url': url
        })
          .then(function (response) {
            console.log(response);
            shortUrl = process.env.VUE_APP_SUBDOMAIN + base_url + response.data.shortUrl;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
      this.shortUrl = shortUrl;
      this.show = !this.show;
    },

    copyUrl: function () {
      navigator.clipboard.writeText(this.shortUrl);
      this.button = "Copied!";
      setTimeout(() => {
        this.url = "";
        this.show = !this.show;
      }, 2000);
    },

    redirect: function () {
      var code = window.location.pathname;
      // Check if the path is not the root ("/")
      if (code !== "/") {
        try {
          const api = process.env.VUE_APP_API + code;
          const response = axios.get(api)
            .then(function (response) {
              if (response.data.url.startsWith("https://") || response.data.url.startsWith("http://")) {
                window.location = response.data.url;
              } else {
                window.location = "https://" + response.data.url;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
};
</script>

<template>
  <div>
    <div class="col col-8 align-middle mt-5 offset-2">
      <div class="card">
        <div class="card-body">

          <div v-if="show">
            <div class="form-group">
              <input type="text" class="form-control" v-model="url" />
            </div>
            <div class="form-group d-flex justify-content-center w-100">
              <button class="btn btn-primary" @click="shorten(url)">Shorten</button>
            </div>
          </div>

          <div v-if="!show">
            <div class="form-group">
              <input type="text" class="form-control" v-model="shortUrl" />
            </div>
            <div id="copyButton" class="form-group d-flex justify-content-center w-100">
              <button class="btn btn-primary" @click="copyUrl">{{ button }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
