<script>
import Home from "./components/Home.vue";
export default {
  name: "App",
  components: {
    Home
  },
};
</script>

<template>
  <div>
    <nav class="navbar navbar-light bg-light">
      <img src="https://www.holcim.com/themes/custom/corporate/components/header/images/holcim_logo_color.svg" alt="Holcim Logo" class="logo">
    </nav>
    <div class="container">
     <home />
    </div>
    <footer class="footer bg-light text-center py-3">
      <div class="container">
        <a href="https://www.linkedin.com/company/holcim" target="_blank" class="mx-2">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://www.holcim.com" target="_blank" class="mx-2">
          <i class="fas fa-globe"></i>
        </a>
        <a href="https://www.instagram.com/holcim" target="_blank" class="mx-2">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://github.com/eits-lafargeholcim" target="_blank" class="mx-2">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://x.com/holcim" target="_blank" class="mx-2">
          <i class="fab fa-twitter"></i>
        </a>
      </div>
    </footer>
  </div>
</template>
